const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    data: {
        type: Object,
        required: true,
    },
});

module.exports = mongoose.models && mongoose.models.Categories_en
    ? mongoose.models.Categories_en
    : mongoose.model("Categories_en", postSchema, 'categories_en', false);
