// import CategoriesEn from "../models/CategoriesEn.js";
// import CategoriesHy from "../models/CategoriesHy.js";
// import CategoriesRu from "../models/CategoriesRu.js";
// import Pages from "../models/Pages.js";
// @ts-ignore

const CategoriesEn = require("../models/CategoriesEn");
const CategoriesHy = require("../models/CategoriesHy");
const CategoriesRu = require("../models/CategoriesRu");
const Pages = require("../models/Pages");
const Menus = require("../models/Menus");

// @ts-ignore
function parseClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

// @ts-ignore
function buildTree(array, parentId, locale) {
    let children = []

    // @ts-ignore
    const reduced = array.map((el) => {
        let hasChildOrNot = el?.menus?.[0]?.children ? el?.menus?.[0]?.children : el?.menus
        return {
            ...el,
            // @ts-ignore
            slug: hasChildOrNot?.map((item) => item?.translations.find((translation) => translation.locale === locale)?.url_key || el.url_key).join(),
            // @ts-ignore
            translated_name: el?.translations.find((translation) => translation.locale === locale)?.name || el.name
        }
    })

    for (let i = 0, length = reduced.length; i < length; i++) {
        if (reduced[i].parent_id === parentId) {
            let child = reduced[i]
            child.children = buildTree(reduced, child.id, locale)
            children.push(child)
        }
    }
    return children
}


// @ts-ignore
// const getChildrens = (res, locale) => {
// // @ts-ignore
//
//     const reduced = res.map((el) => {
//         let hasChildOrNot = el?.menus?.[0]?.children ? el?.menus?.[0]?.children : el?.menus
//         return {
//             ...el,
//             // @ts-ignore
//             slug: hasChildOrNot?.map((item) => item?.translations.find((translation) => translation.locale === locale)?.url_key || el.url_key).join(),
//             // slug: el?.menus[0]?.url_key || "",
//             // @ts-ignore
//             translated_name: el?.translations.find((translation) => translation.locale === locale)?.name || el.name
//         }
//     })
// // @ts-ignore
// //     reduced.map(el => console.log(el, "eeeeeeeeeeeeeeeeeeeeeee"))
//     // @ts-ignore
//     const subMenusArray = reduced.filter((menu) => menu.parent_id)
//
//     // @ts-ignore
//     return reduced.filter((menu) => !menu.parent_id).map(parentMenu => {
//         let children = []
//         // @ts-ignore
//         let findedSubMenu = subMenusArray.filter(subMenu => subMenu.parent_id === parentMenu.id)
//         if (findedSubMenu.length) {
//             children.push(...findedSubMenu)
//         }
//
//
//         return {
//             ...parentMenu,
//             children: children
//         }
//     })
// }


// @ts-ignore
function Get_Menus(locale) {

    return new Promise((resolve, reject) => {
        Menus.aggregate([
            {
                $lookup: {
                    from: "pages",
                    localField: "page_id",
                    foreignField: "id",
                    as: "menus",
                },
            },
            {
                $match: {
                    $or: [
                        {status: 1},
                        {status: "1"},
                    ]
                }
            }

        ])
            // @ts-ignore
            .then((res) => {
                let tree = buildTree(res, null, locale)
                // console.log(tree, "trtrtrtrtrrtrtrtrtrttrtr")

                // const sorted = getChildrens(res, locale)


                // @ts-ignore

                resolve(parseClone(tree));
            })
            // @ts-ignore
            .catch((err) => reject(err));
    });

}

// @ts-ignore
function Get_Categoryes(locale) {
    const modulesInfo = {
        en: CategoriesEn,
        hy: CategoriesHy,
        ru: CategoriesRu,
    };

    if (locale !== "catchAll") {
        // @ts-ignore
        const Module = modulesInfo[locale];
        return new Promise((resolve, reject) => {
            Module.find()
                // @ts-ignore
                .then((res) => {

                    const clonedData = parseClone(res[0]?.data)
                    resolve(clonedData);
                })
                // @ts-ignore
                .catch((err) => reject(err));
        });
    } else {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }
}

// @ts-ignore


// @ts-ignore
function getCategoryBySlug(slug, locale) {
    return new Promise((resolve, reject) => {
        const modulesInfo = {
            en: CategoriesEn,
            hy: CategoriesHy,
            ru: CategoriesRu,
        };
        // @ts-ignore
        const Module = modulesInfo[locale];
        // @ts-ignore
        Module.findOne().then((res) => {
            // @ts-ignore
            var asd = res.data.categories[0].children.filter((e) => e.slug == slug);
            resolve(asd);
        });
    });
}

//
// exports.Get_Menus = Get_Menus;
// exports.getCategoryBySlug = getCategoryBySlug;
// exports.Get_Categoryes = Get_Categoryes;

module.exports = {
    Get_Menus,
    getCategoryBySlug,
    Get_Categoryes,

}