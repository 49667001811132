const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    _id: mongoose.Schema.Types.ObjectId,
    id: {
        type: Number,
        required: true,
    },
    code: {
        type: String,
        required: true,
    },
    value: {
        type: String,
        required: true,
    },
    locale_code: {
        type: String,
        required: true,
    },
}, {strict: false});


module.exports = mongoose.models && mongoose.models.Core_config
    ? mongoose.models.Core_config
    : mongoose.model("Core_config", postSchema, "core_config", false);