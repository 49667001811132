const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    data: {
        type: Object,
        required: true,
    }
});

module.exports = mongoose.models && mongoose.models.Categories_ru
    ? mongoose.models.Categories_ru
    : mongoose.model("Categories_ru", postSchema, 'categories_ru', false);
