const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    attribute_id: {
        type: Number,
        required: true,
    }
});

module.exports = mongoose.models && mongoose.models.Attribute_options
    ? mongoose.models.Attribute_options
    : mongoose.model("Attribute_options", postSchema, 'attribute_options', false);
