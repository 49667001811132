// import Channel_info from "../models/Channel_info.js";
// import Sliders from "../models/Sliders.js";
// import CoreConfig from "../models/CoreConfig.js";
// import Social from "../models/Social.js";
// import Translations from "../models/Translations.js";
const Channel_info = require("../models/Channel_info");
const Sliders = require("../models/Sliders");
const CoreConfig = require("../models/CoreConfig");
const Social = require("../models/Social");
const Translations = require("../models/Translations");

// @ts-ignore
function detectLocale(locale, locale_code){
    if(locale_code){
        if(locale !== "catchAll"){
            if(locale_code !== locale){
                return true
            }
        }else if(locale_code !== 'en'){
            return true
        }
    }
}


function Get_SliderData() {
    return new Promise((resolve, reject) => {
        Sliders.find()
            // @ts-ignore
            .then((res) => resolve({sliders: JSON.parse(JSON.stringify(res))}))
            // @ts-ignore
            .catch((err) => reject(err));
    });
}

// @ts-ignore
function Get_Settings(locale) {
    const storeName = process.env.databaseName;
    const p1 = new Promise((resolve, reject) => {
        Channel_info.find()
            // @ts-ignore
            .then((res) => resolve({channel_info: JSON.parse(JSON.stringify(res))}))
            // @ts-ignore
            .catch((err) => reject(err));
    });
    const p4 = new Promise((resolve, reject) => {
        CoreConfig.find({
            code: {
                $in: [
                    "general.info.general.phone",
                    "general.info.general.footer_email",
                    "general.info.general.footer_address",
                    "general.content.custom_scripts.custom_css",
                    "general.content.custom_scripts.custom_javascript",
                ],
            }

        })
            // @ts-ignore
            .then((res) => {
                // @ts-ignore
                const store_info = res
                    // @ts-ignore
                    .map(({code, value, locale_code}) => {
                        let [, , , key] = code.split(".");
                        if (key == "custom_javascript") {
                            key = "custom_js";
                        }
                        if(detectLocale(locale, locale_code)) return

                        return value ? {[key]: value} : "";
                    })
                    // @ts-ignore
                    .reduce((acc, next) => {
                        return {
                            ...acc,
                            ...next,
                        };
                    }, {});
                resolve({store_info: JSON.parse(JSON.stringify(store_info))});
            })
            // @ts-ignore
            .catch((err) => reject(err));
    });

    const p5 = new Promise((resolve, reject) => {
        Social.find()
            // @ts-ignore
            .then((res) => {
                resolve({social: JSON.parse(JSON.stringify(res))})


            })
            // @ts-ignore
            .catch((err) => reject(err));

    });

    return new Promise((resolve, reject) => {
        Promise.all([p1, p4, p5]).then(async (responseArray) => {
            let [customSettingsCollection] = await Promise.all([responseArray.reduce((acc, next) => {
                // @ts-ignore
                const [key] = Object.keys(next);
                return {
                    // @ts-ignore
                    ...acc,
                    // @ts-ignore
                    [key]: next[key],
                };
            }, {})]);
            // @ts-ignore
            customSettingsCollection.folder_name = storeName;
            resolve(customSettingsCollection);
        });
    });
}
// @ts-ignore
function Get_Core_Config(locale) {
    return new Promise((resolve, reject) => {
        CoreConfig.find({
            code: {
                $in: [
                    "general.gdpr.general.content",
                    "general.gdpr.general.title",
                    "general.gdpr.general.active",
                    "catalog.products.homepage.out_of_stock_items",
                    "catalog.inventory.stock_options.backorders",
                    "catalog.products.guest-checkout.allow-guest-checkout",
                    "theme.blog.active",
                    "theme.contact_us.active",
                    "sales.tracking.tracking.active",
                    "theme.subscription.active",
                    "catalog.products.guest-checkout.allow-guest-checkout",
                    "customer.settings.social_login.enable_linkedin",
                    "customer.settings.social_login.enable_facebook",
                    "customer.settings.social_login.enable_twitter",
                    "customer.settings.social_login.enable_github",
                    "customer.settings.social_login.enable_google",
                    "general.cash_control.version",
                    "general.content.footer.footer_toggle",
                    "general.content.footer.footer_content",
                ],
            },
        })
            // @ts-ignore
            .then((res) => {
                // @ts-ignore
                const core_conf = res
                    // @ts-ignore
                    .map(({code, value = "1", locale_code}) => {
                        let key = code.replace(/\./g, "_");
                        let replaceSlash = key.replace(/\-/g, "_");
                        if(detectLocale(locale, locale_code)) return

                        return {[replaceSlash]: value};
                    })

                    // @ts-ignore
                    .reduce((acc, next) => {
                        return {
                            ...acc,
                            ...next,
                        };
                    }, {});
                resolve(core_conf);
            })
            // @ts-ignore
            .catch((err) => reject(err));
    });
}

// @ts-ignore
function Get_ShopId(query) {
    return new Promise((reslove, reject) => {
        let newQuery;
        if (typeof query === "object") {
            newQuery = query.locale
        } else {
            newQuery = query
        }
        // @ts-ignore
        CoreConfig.findOne({code: newQuery}).then((res) => {
            reslove(JSON.parse(JSON.stringify(res)))
            // @ts-ignore
        }).catch((err) => console.error(err))
    })
}

// @ts-ignore
function Get_Translations(query) {
    return new Promise((resolve, reject) => {
        // @ts-ignore
        let newQuery;

        if (typeof query === "object") {
            newQuery = query.locale
        } else {
            newQuery = query
        }
        // @ts-ignore
        Translations.findOne({lang: newQuery}).then((res) => {
            // @ts-ignore
            resolve({[newQuery]: {translations: res?.data ? JSON.parse(JSON.stringify(res.data)) : {}}});
        });
    });
}

module.exports = {
    Get_Settings,
    Get_Core_Config,
    Get_Translations,
    Get_ShopId,
    Get_SliderData,
};
//exports.Get_Settings = Get_Settings;
// exports.Get_Core_Config = Get_Core_Config;
// exports.Get_ShopId = Get_ShopId;
// exports.Get_Translations = Get_Translations
