const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    _id: mongoose.Schema.Types.ObjectId,
    id: {
        type: Number,
        required: true,
    },
    translations: {
        type: Object,
        required: true,
    },
    code: {
        type: String,
        required: true,
    },
    position: {
        type: Number,
        required: true,
    }

}, {strict: false});

module.exports = mongoose.models && mongoose.models.Attributes
    ? mongoose.models.Attributes
    : mongoose.model("Attributes", postSchema, 'attributes', false);

