// import Blogs from "../models/Blogs.js";

// import {IBlogsLocale} from "../types/blogs";

// @ts-ignore
// import {IBlogsLocale} from "../types/pages";

const Blogs = require("../models/Blogs");

// @ts-ignore
function Get_Blogs({locale: locale, page = 1, limit = 6}) {
    return new Promise((resolve, reject) => {
        // @ts-ignore
        Blogs.countDocuments({}).exec((count_error, count) => {
            if (count_error) {
                let res;
                // @ts-ignore
                return res.json(count_error);
            }
            const pageCount = Math.ceil(count / limit);
            const skip = (+page - 1) * limit;
            Blogs.find()
                .skip(skip)
                ///   .limit(+limit)
                // @ts-ignore
                .exec((err, blogs) => {
                    // let translatedData:Array<IBlogsLocale> = [];
                    // @ts-ignore

                    let translatedData = []

                    // @ts-ignore
                    blogs.map((blog) => {
                        // @ts-ignore

                        let translated = blog?.translations.find((translate) => {
                            if (translate.locale === locale) {
                                const locale = JSON.parse(JSON.stringify({
                                    ...translate,
                                    image: blog.image,
                                    created_at: blog.created_at
                                }));
                                translatedData?.push(locale);
                            }
                        });
                        translatedData?.push(translated);
                    });
                    // @ts-ignore
                    const newtranslatedData = translatedData.filter(function (element) {
                        return element !== undefined;
                    });

                    resolve({
                        data: newtranslatedData,
                        meta: {
                            current_page: page,
                            total: pageCount,
                        },
                        links: {},
                    });
                });
        });
    });
}

// @ts-ignore
function Get_Blog_By_Slug({locale: locale, url_key}) {
    return new Promise((resolve, reject) => {
        Blogs.findOne({url_key})
            // @ts-ignore
            .then((blog) => {
                // @ts-ignore
                let translatedData = [];
                // @ts-ignore
                blog?.translations.map((item) => {
                    if (item.locale === locale) {
                        const locale = JSON.parse(JSON.stringify({
                            ...item,
                            image: blog.image,
                            created_at: blog.created_at
                        }));
                        translatedData.push(locale);
                    }
                });
                // @ts-ignore
                const newtranslatedData = translatedData.filter(function (element) {
                    return element !== undefined;
                });
                resolve({data: newtranslatedData});
            })
            // @ts-ignore
            .catch((err) => reject(err));
    });
}

module.exports = {
    Get_Blogs,
    Get_Blog_By_Slug,

};