const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    _id: mongoose.Schema.Types.ObjectId,
    product_id: {
        type: Number,
        required: true,
    },
    attribute_id: {
        type: Number,
        required: true,
    },
}, {strict: false});

module.exports = mongoose.models && mongoose.models.Product_super_attributes
    ? mongoose.models.Product_super_attributes
    : mongoose.model("Product_super_attributes", postSchema, 'product_super_attributes', false);
