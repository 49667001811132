const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    _id: mongoose.Schema.Types.ObjectId,
    parent_id: {
        type: Number,
        required: true,
    },
    child_id: {
        type: Number,
        required: true,
    },
}, {strict: false});

module.exports = mongoose.models && mongoose.models.Related_products
    ? mongoose.models.Related_products
    : mongoose.model("Related_products", postSchema, "products_relations", false);
