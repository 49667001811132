import {megaUrl, url} from "../helper";

import {connectMongoose} from "../models/connection";


/******************* controllers import *************************/

const PageControllers = require("../controllers/PageControllers");
const PorductController = require("../controllers/PorductController");
const CategoryControllers = require("../controllers/CategoryController.ts");
const FilterControllers = require("../controllers/FilterControllers");
const BuilderController = require("../controllers/BuilderController");
const SettingsController = require("../controllers/SettingsController.ts");
const BlogControllers = require("../controllers/BlogControllers");


const shopApi = {

    customerResetPassword: async (object: any) => {
        const response = await fetch(`${megaUrl}/api/customer/reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(object),
        });
        return await response.json();
    },

    getBrands: async ({
                          options = {
                              locale: ""
                          }
                      }: { options?: { locale: string } }) => {
        const response = await fetch(`${url}/db/attributes?code=brand&locale=${options.locale}`);
        return await response.json();
    },

    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @param options
     * @return {Promise<object>}
     */
    getProductBySlug: async ({
                                 slug, options = {
            lang: '',
            token: false,
            selectedRate: undefined
        }
                             }: { slug: string, options?: { selectedRate: undefined; lang: string; token: boolean } }): Promise<object> => {
        let token = "";
        let lang: string = "en";
        if (options.lang) {
            lang = options.lang;
        }
        if (options.token) {
            token = `?token=${options.token}`;
        }

        const res = await fetch(`${url}/db/product/${slug}${token}?locale=${lang}&rate=${options.selectedRate}`);
        return await res.json();
    },

    /**
     * Returns an array with up-sell product
     *
     * @param productId
     * @param lang
     * @param currency
     * @param rate
     */

    getUpSellProducts: (productId: number, lang = "en", currency = "USD", rate = 1) => {
        return fetch(`${megaUrl}/db/up-sell-products?limit=8&product_id=${productId}&locale=${lang}&currency=${currency}&rate=${rate}`)
            .then(response => response.json())
            .catch(err => console.log(err));
    },

    /**
     * Returns an array with cross-sell products
     *
     * @param productId
     * @param lang
     * @param currency
     * @param rate
     */

    getCrossSellProducts: (productId: number, lang = "en", currency = "USD", rate = 1) => {
        return fetch(`${megaUrl}/db/cross-sell-products?limit=8&product_id=${productId}&locale=${lang}&currency=${currency}&rate=${rate}`)
            .then(response => response.json())
            .catch(err => console.log(err));
    },

    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */

    getSeachProducts: ({
                           query, options = {
            lang: "",
            currency: ""
        }
                       }: { query: any, options?: { lang: any, currency?: any } }): Promise<object> => {
        let locale = "en";
        let currency = "AMD";

        if (options.lang) {
            locale = options.lang;
        }

        if (options.currency) {
            currency = options.currency;
        }
        return fetch(
            `/db/products?search=${query}&locale=${locale}&currency=${currency}&isSearch=true`
        ).then((response) => {
            return response.json()
        });
    },

    /************************* Requests with Controllers ***********************************/

    /**** returns SEO for home page ****/
    /** FIXME IMPORTANT!!! Get_Channels doesn't have locale key **/

    async getHomeMetaAsync(domain: string, locale: undefined) {
        return await connectMongoose(domain).then(() => {
            return PageControllers.Get_Channels(locale).then((meta: any) => {
                return {meta};
            });
        })

    },

    /**** returns home page products ****/
    async getHomeProductsAsync(options: { locale: undefined; currency: string; rate: any; limit: number; }, domain: any) {
        return await connectMongoose(domain).then(() => {
            return PorductController.Get_New_And_Featured_Products(options).then((products: { featured: any; new: any; bestSellers: any }) => {
                return {
                    featuredProducts: products.featured,
                    newProduct: products.new,
                    bestSellers: products.bestSellers,
                    // minPrices: products.status,
                };
            });
        })
    },
    async getBuilderDataAsync(pageId: number, domain: any) {
        return await connectMongoose(domain).then(() => {
            return BuilderController.Get_Data(pageId).then((response: any) => {
                return response;
            });
        })
    },
    async sliderData(domain: any) {
        return await connectMongoose(domain).then(() => {
            return SettingsController.Get_SliderData().then((response: any) => {
                return response;
            });
        })
    },
    /**** returns categories ****/
    async getCategoriesAsync(locale: any, domain: any) {
        return await connectMongoose(domain).then(() => {
            return CategoryControllers.Get_Categoryes(locale).then((response: any) => {
                return response;
            });
        })
    },

    /**** returns categories page [filters] ****/
    async getFiltersAsync(options: any, domain: any) {
        return await connectMongoose(domain).then(() => {
            return FilterControllers.Get_Filters(options).then((response: any) => {
                return response;
            });
        });
    },

    /**** returns all [filters] ****/
    async getAllFiltersAsync(options: any, domain: any) {
        return await connectMongoose(domain).then(() => {
            return FilterControllers.Get_All_Filters(options).then((response: any) => {
                return response;
            });
        });
    },

    /**** returns categories page [products] ****/

    async GetCategoryProducts(options: any) {
        return await connectMongoose(options.dbName).then(() => {
            return PorductController.GetCategoryProducts(options.slug, options.locale, options.limit).then((response: any) => {
                return response
            })
        })
    },


    /**** returns all products ****/

    async GetAllProducts(options: any) {
        return await connectMongoose(options.dbName).then(() => {
            return PorductController.GetAllProducts(options.locale).then((response: any) => {
                return response
            })
        })
    },


    /****************** return Blogs  ***************/
    getAsyncBlogs: async function (options: { locale: string | undefined, page: string | number | string[], limit: number, dbName: string }) {
        return await connectMongoose(options.dbName).then(() => {
            return BlogControllers.Get_Blogs(options).then((response: any) => {
                return response;
            });
        })
    },
    async getAsyncPagesBySlug(options: { dbName: string; }) {
        return await connectMongoose(options.dbName).then(() => {
            return PageControllers.Get_Page_By_Slug(options).then((response: any) => {
                return response
            });
        })
    },
    async getAsyncBlogsBySlug(options: { locale: string, url_key: string, dbName: string }) {
        return await connectMongoose(options.dbName).then(() => {
            return BlogControllers.Get_Blog_By_Slug(options).then((response: any) => {
                return response
            });
        })
    },

    /*************************** PRODUCT INNER PAGE ASYNC REQUESTS ************************************/

    getProductBySlugAsync(slug: string, options: { selectedRate: any; locale: any }) {
        return PorductController
            .Get_Product_For_Product_Inner_Page(slug, options)
        // .then(response => response)
    },

    getRelatedProductsAsync(options: any) {
        return PorductController
            .Get_Related_Products(options)
        // .then(response => response);
    },

    getBundleProductAsync: (id: any) => {
        return PorductController
            .Get_Bundle_Prods(id)
        // .then(response => {
        //     return response
        // })
    },
    async getShopId(neededIds: any) {
        return SettingsController.Get_ShopId(neededIds).then((res: { value: any; }) => {
            if (res?.value) {
                return res.value;
            }
            return res
        })

    },


// get usd value

    getUsdCurrentValue: () => {
        return fetch(`https://api.coingecko.com/api/v3/simple/price?ids=binance-usd&vs_currencies=usd`)
            .then(res => res.json())
    },

    /********************************** Checkout Page queries ***************************************************/
    getPayments: (locale = false) => {
        let hasLocale = locale ? `?locale=${locale}` : ""
        return fetch(`${megaUrl}/api/payments${hasLocale}`)
            .then(res => res.json())
    },


    checkOrder: (orderId: any, transactionID: any) => {
        let hasOrderId = orderId && transactionID ? `order_id=${orderId}&trans_id=${transactionID}` : ""
        return fetch(`${megaUrl}/api/crypto-check?${hasOrderId}`)
            .then(res => res.json())
    },

    getStripe: () => {
        return fetch(`${megaUrl}/api/checkout/getpk`)
            .then(res => res.json())
    },

    getCountryState: () => {
        return fetch(`${megaUrl}/api/country-states?pagination=0`)
            .then(res => res.json())
    },

    getCountries: () => {
        return fetch(`${megaUrl}/api/countries?pagination=0`)
            .then(res => res.json())
    },

    getCustomerToken: async (token: any, single: any) => {
        // @ts-ignore
        const res = await fetch(`${megaUrl}/api/customer/get?token=${token}`, {single});
        return await res.json();
    },

    getAddresses: (token: any, single: string) => {

        // @ts-ignore
        return fetch(`${megaUrl}/api/addresses?pagination=0&token=${token}`, {single})
            .then(res => res.json())
    },

    getCartToken: (token: any) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        return fetch(`${megaUrl}/api/checkout/cart?token=${token}`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
    },

    getCartCoupon: (methods: string, coupon: any, token: any) => {
        const requestOptions = {
            method: methods === "DELETE" ? "DELETE" : "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                code: coupon,
                token: token,
            }),
        }
        return fetch(`${megaUrl}/api/checkout/cart/coupon`, requestOptions)
            .then((res) => res.json())
    },

    setSaveAddress: (billing: any, shipping: any, cartToken: any, token: any,isFromPayButton:boolean = false) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const mainBody = {
            billing,
            shipping,
            api_token: cartToken,
            token,
        }

        const body = !isFromPayButton ? {
           ...mainBody,
            is_order:1
        } : {
            ...mainBody
        }

        if (!token) {
            delete body.token
        }


        const options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }

        return fetch(`${megaUrl}/api/checkout/save-address`, options)
    },

    setSaveShipping: (shippingMethod: any, cartToken: any, token: string) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const body = {
            shipping_method: shippingMethod === "" ? "free_free" : shippingMethod,
            api_token: cartToken,
            token: token,
        }
        ////FIXME FIX THIS PART
        // if (!token) {
        //     delete body.token
        // }

        const save_shipping = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }

        let tokenURL = token ? "?token=" + token : "";
        return fetch(`${megaUrl}/api/checkout/save-shipping${tokenURL}`, save_shipping)
    },

    setSavePayment: (payment: any, cartToken: any, token: any) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        const body = {
            payment: {method: payment},
            api_token: cartToken,
            token: token,
        }

        if (!token) {
            delete body.token
        }

        const options_payment = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }
        return fetch(`${megaUrl}/api/checkout/save-payment`, options_payment)
    },

    setSaveOrder: (cartToken: any, token: any) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const body = {
            api_token: cartToken,
            token: token,
        }

        if (!token) {
            delete body.token
        }

        const options_order = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        }

        return fetch(`${megaUrl}/api/checkout/save-order`, options_order)
            .then(res => res.json())
    },


    /******************* shipping methods *********************/

    getShippingMethods: (locale: any, token: any) => {
        const realToken = token ? `&token=${token}` : ""
        return fetch(`${megaUrl}/api/shipping?locale=${locale}${realToken}`)
            .then(res => res.json())
    },

    /********************* cart requests ************************/

    getCartTranslation: (cartToken: { cartToken: any; }, customer: { token: any; }, locale: any) => {
        const realToken = customer && customer.token ? `&token=${customer.token}` : ""
        return fetch(`${megaUrl}/api/checkout/cart?api_token=${cartToken.cartToken}${realToken}&locale=${locale}`)
            .then(res => res.json())
    },


    /************************* Account component requests *****************************/

    getForgetPassword: (input: any) => {
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({email: input}),
        };
        return fetch(`${megaUrl}/api/customer/forget-password`, options)
            .then(res => res.json())
    },

    getCustomerLogoutToken: (customer: any) => {
        return fetch(`${megaUrl}/api/customer/logout?token=${customer}`)
    },

    getCheckoutCartToken: () => {
        return fetch(`${megaUrl}/api/checkout/cart/token`)
            .then(res => res.json())
    },

    getCustomerLoginToken: ({email, password}: { email: string, password: string }) => {
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        };

        return fetch(`${megaUrl}/api/customer/login?token=true`, options)
            .then(res => res.json())
    },

    getCheckoutCartAdd: (productID: any, quantity: any, cartToken: any, token: any) => {
        const realToken = token ? `&token=${token}` : ""
        return fetch(`${megaUrl}/api/checkout/cart/add/${productID}?product_id=${productID
        }&quantity=${quantity}&api_token=${cartToken}${realToken}`, {method: "POST"})
            .then(res => res.json())
            .then(data => console.log(data))
    }


};


export default shopApi;
