const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    data: {
        type: Object,
        required: true,
    },
    links: {
        type: Object,
        required: true,
    },
    meta: {
        type: Number,
        required: true,
    },
});

// module.exports =
//   mongoose.models.Channel_info ||
//   mongoose.model("Channel_info", postSchema, "channel_info", false);

// was changed exports by Tigran

module.exports = mongoose.models && mongoose.models.Channel_info
    ? mongoose.models.Channel_info
    : mongoose.model("Channel_info", postSchema, "channel_info", false);

