const Pages = require("../models/Pages.js");
const Channels = require("../models/Channels.js");

function Get_Page_By_Slug({pageSlug, locale}) {
    return new Promise((resolve, reject) => {
        const search =
            typeof parseInt(pageSlug) == "number" && parseInt(pageSlug)
                ? {id: parseInt(pageSlug)}
                : {url_key: pageSlug};

        Pages.find()
            .then((item) => {

                /*** REMEMBER this part ***/
                    // let translatedData = item.map(el => el.translations.find(y => y.url_key === search.url_key && y.url_key !== undefined))
                    // let translatedData = item.map(el => el.translations.find(y => y.locale === locale))
                const translatedData = item.map(el => {
                        return el?.translations.find((translate) => {
                            if (translate.locale === locale && translate.url_key === search.url_key) {
                                return JSON.parse(JSON.stringify(translate));
                            }
                        });
                    })
                const newtranslatedData = translatedData.filter(function (element) {
                    return element !== undefined;
                });
                resolve({data: newtranslatedData});
            })
            .catch((err) => reject(err));
    });
}


/********************** new code ****************************/
function parseClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function Get_Channels(locale) {
    const metaData = [];
    return new Promise((resolve, reject) => {
        Channels.find({locale: {'$ne': locale}}).then((response) => {
            resolve(parseClone(response));
        });

    })
}

// exports.Get_Channels = Get_Channels;
// exports.Get_Page_By_Slug = Get_Page_By_Slug;

module.exports = {
    Get_Channels,
    Get_Page_By_Slug
};