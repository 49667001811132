const Products = require("../models/Products");
const ProductFlat = require("../models/ProductFlat.js");
const ProductImages = require("../models/ProductImages.js");
const RelatedProducts = require("../models/RelatedProducts");
const UpSellProducts = require("../models/UpSellProducts");
const CrosselProducts = require("../models/CrosselProducts");
const ProductInventories = require("../models/ProductInventories.js");
const ProductsCategories = require("../models/ProductsCategories.js");
const ProductSuperAttributes = require("../models/ProductSuperAttributes.js");
const BundleOptions = require("../models/BundleOptions.js");
const BundleProducts = require("../models/BundleProducts.js");
const ProductAttributeValues = require("../models/ProductAttributeValues.js");
const Attributes = require("../models/Attributes.js");
const AttributeOptions = require("../models/AttributeOptions.js");

// @ts-ignore
function parseClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

// @ts-ignore
function makeImageClone(path) {
    return {
        path: path,
        url: `/storage/${path}`,
        large_image_url: `/cache/large/${path}`,
        medium_image_url: `/cache/medium/${path}`,
        small_image_url: `/cache/small/${path}`,
        original_image_url: `/cache/original/${path}`,
    };
}

// @ts-ignore
function defaultFilter({key, options}) {

    let dynamicSearchKey = null;
    switch (typeof options[key]) {
        case "boolean":
            dynamicSearchKey = "boolean_value";
            break;
        default:
            let isnum = /^\d+$/.test(options[key]);
            switch (isnum) {
                case true:
                    dynamicSearchKey = "integer_value";
                    break;
                default:
                    dynamicSearchKey = "text_value";
                    break;
            }
    }

    if (options.isSearch) {
        delete options.isSearch;
        let value = null;
        if (options[key].includes(",")) {
            dynamicSearchKey = "integer_value";
            value = options[key].split(",");
        } else {
            value = options[key];
        }
        return {[dynamicSearchKey]: value};
    } else {
        return {}
    }
}

//
// async function Get_config_variants(id) {
//     return await new Promise( (resolve, reject) => {
//         ProductFlat.find( {parent_id: id} )
//             .then( (res) => {
//                 resolve( res );
//             } ).catch( err => reject( err ) );
//     } )
// }
//

// @ts-ignore
function build({flatProducts, locale, resolve, ...rest}) {
    // @ts-ignore
    const promiseArray = flatProducts.map((item) => {
        return new Promise((resolve, reject) => {
            const product = parseClone(item);
            const productId = product.product_id;
            const p1 = new Promise((resolve, reject1) => {
                ProductImages.find({product_id: productId})
                    // @ts-ignore
                    .then((res) => resolve({ProductImages: res}))
                    // @ts-ignore
                    .catch((err) => reject1(err));
            });
            const p2 = new Promise((resolve, reject2) => {
                ProductFlat.findOne({locale, product_id: productId})
                    // @ts-ignore
                    .then((res) => resolve({productFlat: res}))
                    // @ts-ignore
                    .catch((err) => reject2(err));
            });
            const p3 = new Promise((resolve, reject3) => {
                ProductInventories.findOne({product_id: productId})
                    // @ts-ignore
                    .then((res) => resolve({ProductInventories: res}))
                    // @ts-ignore
                    .catch((err) => reject3(err));
            });
            const p4 = new Promise((resolve, reject4) => {
                Products.findOne({id: productId})
                    // @ts-ignore
                    .then((res) => resolve({Products: res}))
                    // @ts-ignore
                    .catch((err) => reject4(err));
            });

            return Promise.all([p1, p2, p3, p4])
                .then((response) => {
                    const collection = arrayConvertToObject(response);
                    const imagesData = parseClone(collection.ProductImages);
                    const flatData = parseClone(collection.productFlat || []);
                    const inventoriesData = parseClone(collection.ProductInventories || []);
                    const prods = parseClone(collection.Products || []);
                    if (imagesData[0] && imagesData[0].path) {
                        const {path} = imagesData[0];
                        const base_imag = makeImageClone(path);
                        // @ts-ignore
                        const images = imagesData.map((e) => makeImageClone(e.path));
                        // @ts-ignore
                        let variants = [];
                        // @ts-ignore
                        rest && rest.products && rest.products.map((it) => {
                            let x = Object.keys(it);
                            x.find((str) => {
                                if (str === "variants") {
                                    variants = [...it[str]];
                                } else {
                                    return;
                                }
                            });
                        });

                        const obj = {
                            // @ts-ignore
                            ...prods, ...flatData, ...inventoriesData, base_imag, variants, images, // formatted_price: formattedPrice(locale, flatData.price, selectedRate),
                        };
                        resolve(obj);
                    }
                    resolve([]);
                })
                .catch((err) => reject(err));
        });
    });

    return Promise.all(promiseArray).then((response) => {
        let everyThing = null;
        if (rest.prices && rest.prices.length > 0) {
            var setInitialMinPrice = 0;
            var setInitialMaxPrice = 0;
            setInitialMinPrice = Math.min.apply(Math, response.map(function (o) {
                return o.price;
            }));
            setInitialMaxPrice = Math.max.apply(Math, response.map(function (o) {
                return o.price;
            }));

            everyThing = {
                data: response,
                filters: [],
                links: {},
                max_price: rest.prices[1] || 1,
                meta: {},
                total: rest.total,
                dispatches: {
                    setInitialMinPrice: setInitialMinPrice || 1, setInitialMaxPrice: setInitialMaxPrice || 1,
                },
            };
        } else {

            everyThing = {
                [rest.type]: response,
            };
        }
        resolve(everyThing);
    });
}

// @ts-ignore
function buildProductsListCollection({flat, savings, price, ...rest}) {

    if (flat) {
        const {prices, flatProducts} = rest;
        let object;
        const [from, to] = price.split(",");
        // @ts-ignore
        const flatProductsFiltered = flatProducts.filter((flatProduct) => {
            if (flatProduct.price >= from && flatProduct.price <= parseFloat(to) + 5) {
                return flatProduct;
            }
        });
        // @ts-ignore
        build({...rest, flatProducts: flatProductsFiltered});

    } else {
        // @ts-ignore
        build(rest);
    }
}

// @ts-ignore
function arrayConvertToObject(response) {
    // @ts-ignore
    return response.reduce((prev, next) => {
        const keys = Object.keys(next);
        if (keys.length > 1) {
            // @ts-ignore
            const array = keys.reduce((a, n) => {
                return {
                    ...a, [n]: next[n],
                };
            }, {});
            return {...prev, ...array};
        } else {
            // @ts-ignore
            return {...prev, [keys]: next[keys]};
        }
    }, {});
}

//
//
// function formattedPrice(locale, price, rate) {
//
//     if (locale !== "en") {
//         return `${price} ${rate.symbol}`
//     }
//     return `${rate.symbol} ${price}`
// }
//
//
// function changeRate(item, rate) {
//     /**** REMEMBER ADD SPECIAL PRICE CHECK AND CHANGE MIN PRICE MAX PRICE ****/
//     /**** REMEMBER NOT WORK WITH RELATED CONFIG ****/
//     if (item.length > 0) {
//         item.map( (el) => {
//             el.min_price = parseFloat( el.min_price ) * rate;
//             el.max_price = parseFloat( el.max_price ) * rate;
//             el.special_price = parseFloat( el.special_price ) * rate;
//             el.price = parseFloat( el.price ) * rate;
//             return el;
//         } );
//         return item;
//     } else {
//
//         item.min_price = parseFloat( item.min_price ) * rate;
//         item.max_price = parseFloat( item.max_price ) * rate;
//         item.special_price = parseFloat( item.special_price ) * rate;
//         item.price = parseFloat( item.price ) * rate;
//
//         return item;
//
//     }
// }
//
// function getOnlyValidNumbers(array: any[]) {
//     return array.filter( el => el !== null )
// }

const START_DATE = "1970-01-01";

// @ts-ignore
function findMinimalOrMaximalPrice(obj, order) {
    if (obj) {
        const {price, min_price, max_price, special_price} = obj
        let newDate = new Date();
        let pricesArr = []
        let date_from = obj.special_price_from
        let date_to = obj.special_price_to
        const date_now = newDate;
        if (special_price && date_now >= date_from && date_now <= date_to) {
            pricesArr = [price, min_price, max_price, special_price]
        } else if (special_price && date_from === START_DATE && date_to === START_DATE) {
            pricesArr = [price, min_price, max_price, special_price]
        } else {
            pricesArr = [price, min_price, max_price]
        }

        if (price) {
            if (order === 1) {
                return pricesArr.filter(el => el !== null).sort((a, b) => b - a)[0]
            } else {
                return pricesArr.filter(el => el !== null).sort((a, b) => a - b)[0]
            }
        }
    }

}

// @ts-ignore
function Get_New_And_Featured_Products(opts) {
    const {locale, currency, rate, limit} = opts
    return new Promise((resolve, reject) => {
        const newProducts = new Promise((resolve, reject1) => {
            let changedData;
            // @ts-ignore
            Products.aggregate([{
                $lookup: {
                    from: "product_flat",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_flat",
                    pipeline: [{
                        $match: {
                            $and: [{new: 1}, {locale: locale}, {parent_id: null}]
                        }
                    }]
                }
            }, {
                $lookup: {
                    from: "product_inventories",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_inventories"
                }
            }, {
                $lookup: {
                    from: "product_images",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_images",
                }
            }, {
                $match: {"product_flat.new": 1}
            }])
                .limit(limit)
                // @ts-ignore
                .then((res) => {
                    res = JSON.parse(JSON.stringify(res))
                    // @ts-ignore
                    let modifiedRes = res.map(el => {
                        return {...el, min_price: el.product_flat[0].min_price}
                    })
                    resolve(modifiedRes)
                })
                // @ts-ignore
                .catch((err) => reject1(err))
        })
        const featuredProducts = new Promise((resolve, reject2) => {
            Products.aggregate([{
                $lookup: {
                    from: "product_flat",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_flat", pipeline: [{
                        $match: {
                            $and: [{featured: 1}, {locale: locale}, {parent_id: null}]
                        }
                    }]
                }
            }, {
                $lookup: {
                    from: "product_inventories",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_inventories"
                }
            }, {
                $lookup: {
                    from: "product_images",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_images",
                }
            }, {
                $match: {"product_flat.featured": 1}
            }])
                .limit(limit)
                // @ts-ignore
                .then((res) => {
                    res = JSON.parse(JSON.stringify(res))
                    // @ts-ignore
                    let modifiedRes = res.map(el => {
                        return {...el, min_price: el.product_flat[0].min_price}
                    })
                    resolve(modifiedRes)
                })
                // @ts-ignore
                .catch((err) => reject2(err))
        })
        const bestSellers = new Promise((resolve, reject2) => {
            Products.aggregate([{
                $lookup: {
                    from: "product_flat",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_flat",
                    pipeline: [{
                        $match: {
                            $and: [
                                {bestsellers: 1},
                                {locale: locale}, {parent_id: null}
                            ]
                        }
                    }]
                }
            },
                {
                    $lookup: {
                        from: "product_inventories",
                        localField: "id",
                        foreignField: "product_id",
                        as: "product_inventories"
                    }
                },
                {
                    $lookup: {
                        from: "product_images",
                        localField: "id",
                        foreignField: "product_id",
                        as: "product_images",
                    }
                },
                {
                $match: {"product_flat.bestsellers": 1}
                }
            ])
                .limit(limit)
                // @ts-ignore
                .then((res) => {
                    res = JSON.parse(JSON.stringify(res))
                    // @ts-ignore
                    let modifiedRes = res.map(el => {
                        return {...el, min_price: el.product_flat[0].min_price}
                    })
                    resolve(modifiedRes)
                })
                // @ts-ignore
                .catch((err) => reject2(err))
        })
            .catch(err => reject(err));

        return Promise
            .all([featuredProducts, newProducts, bestSellers])
            .then((response) => {
                resolve({
                    new: response[1], featured: response[0], bestSellers: response[2]
                });
            });
    });
}

// @ts-ignore
function Get_Product_list(options) {
    //limit, category_id, currency,
    const {locale: defaultLocale, limit: limitproduct, page, ...rest} = options;
    const limit = limitproduct || 20;
    // @ts-ignore
    let locale;
    if (typeof defaultLocale != "string") {
        locale = defaultLocale[0];
    } else {
        locale = defaultLocale;
    }
    let searchKeys = {};
    for (let key in options) {
        if (key !== "limit" && key !== "category_id" && key !== "currency" && key !== "locale" && key !== "page") {
            switch (key) {
                case "savings":
                    break;
                case "price":
                    // const [from, to] = options["price"].split(",");
                    // searchKeys = {
                    //   ...searchKeys,
                    //   prices: { $gte: from + ".0000", $lte: to + ".0000" },
                    // };
                    break;
                default:
                    searchKeys = {
                        // @ts-ignore
                        ...searchKeys, ...defaultFilter({key, options, searchKeys}),
                    };
            }
        }
    }

    return new Promise((resolve, reject) => {
        ProductsCategories
            .find({slug: options.slug})
            // @ts-ignore
            .then((res) => {
                // @ts-ignore
                const productIdsByCategory = res.map((e) => e.product_id);

                const paramsArray = Object.keys(JSON.parse(JSON.stringify(searchKeys)));
                const buildQueryParams = paramsArray.reduce((acc, next) => {
                    // @ts-ignore
                    if (typeof searchKeys[next] == "object" && searchKeys[next]?.length > 0) {
                        // @ts-ignore
                        return {...acc, [next]: {$in: searchKeys[next]}};
                    } else {
                        // @ts-ignore
                        return {...acc, [next]: searchKeys[next]};
                    }
                }, {});
                if (Object.keys(buildQueryParams)[0] !== "text_value") {
                    // @ts-ignore
                    let productIds;
                    ProductAttributeValues
                        .find({...buildQueryParams})
                        // @ts-ignore
                        .then((res) => {
                            // @ts-ignore
                            productIds = productIdsByCategory.filter((id) => {
                                // @ts-ignore
                                const find = res.find((e) => e.product_id == id);
                                if (find) {
                                    return id;
                                }
                            });

                            if (productIds.length === 0) {
                                resolve([]);
                            }
                            const productsPromise = new Promise((resolve, reject) => {
                                // @ts-ignore
                                let arrayData = [];
                                // @ts-ignore
                                Products.find({id: {$in: productIds}, type: "simple"}).then((products) => {
                                    arrayData = parseClone(products);
                                    Products.find({
                                        // @ts-ignore
                                        id: {$in: productIds}, type: "configurable",
                                    })
                                        // @ts-ignore
                                        .then((items) => {

                                            if (items.length === 0) {
                                                // @ts-ignore
                                                resolve({arrayData});
                                            }
                                            // @ts-ignore
                                            items.map((item, index) => {
                                                const itemProduct = parseClone(item);

                                                ProductFlat.find({parent_id: itemProduct.id})
                                                    // @ts-ignore
                                                    .then((res) => {
                                                        return (itemProduct["variants"] = res);
                                                    })
                                                    .then(() => {
                                                        let productsData = [];
                                                        // @ts-ignore
                                                        productsData = [...arrayData];
                                                        resolve(productsData);
                                                    });
                                            });
                                        });
                                })
                                    // @ts-ignore
                                    .catch(err => reject(err));
                            });

                            const minMaxPricePromise = new Promise((resolve, reject) => {
                                /**
                                 *  @info: Min max price Only category ID , wihtout all filtered attributes, need to overwrite
                                 *
                                 * */
                                    // @ts-ignore
                                let object;
                                // @ts-ignore
                                let date_now = null;
                                if (productIds.length > 0) {
                                    object = {
                                        // @ts-ignore
                                        locale: locale, product_id: {$in: productIds},
                                    };
                                } else {
                                    object = {
                                        // @ts-ignore
                                        locale: locale,
                                    };
                                }

                                if (options["price"]) {
                                    const [from, to] = options["price"].split(",");
                                    object = {
                                        ...object, price: {
                                            $gte: from + ".0000", $lte: parseFloat(to) + 10 + ".0000",
                                        },
                                    };
                                }
                                if (options["savings"]) {
                                    let d = new Date(), month = "" + (d.getMonth() + 1), day = "" + d.getDate(),
                                        year = d.getFullYear();
                                    if (month.length < 2) month = "0" + month;
                                    if (day.length < 2) day = "0" + day;
                                    date_now = new Date(`${year}-${month}-${day}`).getTime();
                                    date_now = "" + date_now;
                                    date_now = parseInt(date_now.slice(0, -3));

                                    object = {
                                        ...object, special_price: {$ne: null},
                                    };

                                    ProductFlat.where("special_price_from")
                                        .lte(date_now)
                                        .where("special_price_to")
                                        .gte(date_now)
                                        .countDocuments({...object})
                                        // @ts-ignore
                                        .exec((count_error, count) => {
                                            const pageCount = Math.ceil(count / limit);
                                            //////    const skip = (+page - 1) * limit;
                                            ProductFlat.find({
                                                // @ts-ignore
                                                ...object,
                                            })
                                                // .skip(skip)
                                                // .limit(+limit)
                                                .where("special_price_from")
                                                // @ts-ignore
                                                .lte(date_now)
                                                .where("special_price_to")
                                                // @ts-ignore
                                                .gte(date_now)
                                                // @ts-ignore
                                                .then((flatProducts) => {
                                                    const prices = flatProducts
                                                        // @ts-ignore
                                                        .map((item) => parseInt(item.price))
                                                        // @ts-ignore
                                                        .filter((e) => e);

                                                    resolve({
                                                        total: 20,
                                                        flatProducts,
                                                        page: page || 1,
                                                        prices: [0, prices[prices.length - 1] || 1000],
                                                        price: options["price"],
                                                    });
                                                })
                                                // @ts-ignore
                                                .catch(err => reject(err));
                                        });
                                } else {
                                    ProductFlat
                                        .countDocuments({...object})
                                        // @ts-ignore
                                        .exec((count_error, count) => {
                                            const pageCount = Math.ceil(count / limit);
                                            const skip = (+page - 1) * limit;
                                            // @ts-ignore
                                            ProductFlat.find({...object})
                                                // .skip(skip)
                                                // .limit(+limit)

                                                // @ts-ignore
                                                .then((flatProducts) => {
                                                    const prices = flatProducts
                                                        // @ts-ignore
                                                        .map((item) => parseInt(item.price))
                                                        // @ts-ignore
                                                        .filter((e) => e);

                                                    resolve({
                                                        total: pageCount,
                                                        flatProducts,
                                                        page: page || 1,
                                                        prices: [0, prices[prices.length - 1] || 1000],
                                                        price: options["price"],
                                                    });
                                                });
                                        });
                                }
                            });

                            return Promise
                                .all([productsPromise, minMaxPricePromise])
                                .then((response) => {
                                    const productsAndMinMaxPrice = arrayConvertToObject(parseClone(response));

                                    if (options["savings"] || options["price"]) {
                                        buildProductsListCollection({
                                            // @ts-ignore
                                            locale,
                                            resolve,
                                            flat: true,
                                            price: productsAndMinMaxPrice.price,
                                            savings: options["savings"], ...productsAndMinMaxPrice,
                                        });
                                    } else {
                                        buildProductsListCollection({
                                            // @ts-ignore
                                            page, locale, resolve, // price: options["price"],
                                            flat: false, ...productsAndMinMaxPrice,
                                        });
                                    }
                                });
                        });
                } else {
                    ProductFlat
                        .aggregate([
                            {
                                "$match": {
                                    // @ts-ignore
                                    name: {$regex: Object.values(buildQueryParams)[0], $options: "i"}, locale,
                                }
                            },
                            {
                                $lookup: {
                                    from: "product_flat",
                                    localField: "product_id",
                                    foreignField: "product_id",
                                    as: "product_flat",
                                    pipeline: [
                                        {
                                            $match: {
                                                // @ts-ignore
                                                locale: locale
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                $lookup: {
                                    from: "product_inventories",
                                    localField: "product_id",
                                    foreignField: "product_id",
                                    as: "product_inventories"
                                }
                            },
                            {
                                $lookup: {
                                    from: "product_images",
                                    localField: "product_id",
                                    foreignField: "product_id",
                                    as: "product_images",
                                }
                            },
                            {
                                $lookup: {
                                    from: "products",
                                    localField: "product_id",
                                    foreignField: "id",
                                    as: "products"
                                }
                            },
                            {
                                $lookup: {
                                    from: "attributes",
                                    pipeline: [
                                        {
                                            $match: {
                                                $and: [{code: "sku"}]
                                            }
                                        },
                                    ],
                                    as: "sku_option"
                                },
                            },

                        ])
                        // @ts-ignore
                        .then((res) => {
                            // @ts-ignore
                            res = parseClone(res)
                            // @ts-ignore
                            const modifiedRes = res.map(item => {
                                let type = item.products[0].type
                                let base_image;
                                if (item.product_images.length > 0) {
                                    base_image = makeImageClone(item.product_images[0].path)
                                    item.product_images[0] = base_image
                                }

                                return {...item, type}
                            })

                            resolve({data: modifiedRes})

                        })
                }
            })
            // @ts-ignore
            .catch(err => reject(err));
    });
}


// @ts-ignore
const Get_Product_For_Product_Inner_Page = (productSlug, options) => {
    const {locale} = options;
    return new Promise((resolve, reject) => {
        Products.aggregate([
            {
                $lookup: {
                    from: "product_flat",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_flat",
                    pipeline: [
                        {
                            $match: {
                                $and: [
                                    {url_key: productSlug},
                                    {locale: locale}
                                ]
                            }
                        }
                    ]
                }
            },
            {
                $lookup: {
                    from: "attributes",
                    pipeline: [
                        {
                            $match: {
                                $and: [{code: "sku"}]
                            }
                        },
                    ],
                    as: "sku_option"
                },
            },
            {
                $lookup: {
                    from: "attributes",
                    pipeline: [
                        {
                            $match: {
                                $and: [{code: "short_description"}]
                            }
                        },
                    ],
                    as: "short_description_option"
                },
            },
            {
                $lookup: {
                    from: "attributes",
                    pipeline: [
                        {
                            $match: {
                                $and: [{code: "description"}]
                            }
                        },
                    ],
                    as: "description_option"
                },
            },
            {
                $lookup: {
                    from: "product_super_attributes",
                    localField: "id",
                    foreignField: "product_id",
                    as: "attributes",
                    pipeline: [
                        {
                            $lookup: {
                                from: "attributes",
                                localField: "attribute_id",
                                foreignField: "id",
                                as: "attributes_values",
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "attribute_options",
                                            localField: "id",
                                            foreignField: "attribute_id",
                                            as: "attribute_options",
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                $lookup: {
                    from: "product_images",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_images",
                }
            },
            {
                $lookup: {
                    from: "product_inventories",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_inventories"
                }
            },
            {
                $lookup: {
                    from: "products",
                    localField: "id",
                    foreignField: "parent_id",
                    as: "products",
                    pipeline: [
                        {
                            $lookup: {
                                from: "product_attribute_values",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_attribute_values",

                            }
                        },
                        {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        },
                        {
                            $lookup: {
                                from: "product_inventories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_inventories"
                            }
                        },
                        {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [
                                    {
                                        $match: {
                                            locale: locale
                                        },
                                    }
                                ]
                            },
                        }
                    ]
                }
            },
            {
                $lookup: {
                    from: "product_bundle_options",
                    localField: "id",
                    foreignField: "product_id",
                    as: "bundle_products",
                    pipeline: [
                        {
                            $lookup: {
                                from: "product_bundle_option_products",
                                localField: "id",
                                foreignField: "product_bundle_option_id",
                                as: "bundle_product_options",
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "product_id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [
                                                {
                                                    $lookup: {
                                                        from: "product_inventories",
                                                        localField: "id",
                                                        foreignField: "product_id",
                                                        as: "product_inventories"
                                                    }
                                                },
                                                {
                                                    $lookup: {
                                                        from: "product_images",
                                                        localField: "product_id",
                                                        foreignField: "product_id",
                                                        as: "product_images",
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "product_id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    },
                                ]
                            },
                        }
                    ]
                }
            },
            {
                $lookup: {
                    from: "products_categories",
                    localField: "id",
                    foreignField: "product_id",
                    as: "categories",
                }
            },
            {$match: {"product_flat.url_key": productSlug}}])
            // @ts-ignore
            .then((res) => {
                //// let newMinPrice = res[0].products.map( (item: { product_flat: any[]; }) => findMinimalOrMaximalPrice( item.product_flat[0], -1 ) )
                // @ts-ignore
                let newMinPrice = res[0]?.products.map((item) => item.product_flat[0].price)
                    // @ts-ignore
                    .sort((a, b) => a - b)[0]
                if (!newMinPrice) {
                    newMinPrice = null
                }
                res = JSON.parse(JSON.stringify(res))


                if (res[0]?.type == 'bundle') {
                    // @ts-ignore
                    const modifiedRes = res.map((item) => {
                        let base_image;
                        // @ts-ignore
                        item.bundle_products.map((itemPr) => {
                            // @ts-ignore
                            itemPr.bundle_product_options.map((el) => {

                                if (el.product_images.length > 0) {
                                    // @ts-ignore
                                    el.product_images.map((y, ind) => {
                                        // base_image =
                                        //     {
                                        //         path: y.path,
                                        //         url: `/storage/${y.path}`,
                                        //         large_image_url: `/cache/large/${y.path}`,
                                        //         medium_image_url: `/cache/medium/${y.path}`,
                                        //         small_image_url: `/cache/small/${y.path}`,
                                        //         original_image_url: `/cache/original/${y.path}`,
                                        //     };
                                        base_image = makeImageClone(y.path)
                                        el.product_images[ind] = base_image
                                    })
                                }
                                if (el.product_flat[0].product_images.length > 0) {
                                    // @ts-ignore
                                    el.product_flat[0].product_images.map((y, ind) => {
                                        // base_image = {
                                        //     path: y.path,
                                        //     url: `/storage/${y.path}`,
                                        //     large_image_url: `/cache/large/${y.path}`,
                                        //     medium_image_url: `/cache/medium/${y.path}`,
                                        //     small_image_url: `/cache/small/${y.path}`,
                                        //     original_image_url: `/cache/original/${y.path}`,
                                        // };
                                        base_image = makeImageClone(y.path)

                                        el.product_flat[0].product_images[ind] = base_image
                                    })
                                }
                                return el
                            })
                            return itemPr
                        })
                        if (item.product_images.length > 0) {
                            // @ts-ignore
                            item.product_images.map((x, ind) => {
                                // base_image = {
                                //     path: x.path,
                                //     // @ts-ignore
                                //     url: `/storage/${x.path}`,
                                //     large_image_url: `/cache/large/${x.path}`,
                                //     medium_image_url: `/cache/medium/${x.path}`,
                                //     small_image_url: `/cache/small/${x.path}`,
                                //     original_image_url: `/cache/original/${x.path}`,
                                // };
                                base_image = makeImageClone(x.path)

                                // @ts-ignore
                                item.product_images[ind] = base_image
                            })

                        }
                        return item
                    })
                    resolve(modifiedRes)
                } else {
                    // @ts-ignore
                    const modifiedRes = res.map(item => {
                        let base_image;
                        // @ts-ignore
                        item.products.map((el) => {
                            if (el.product_images.length > 0) {
                                // @ts-ignore
                                el.product_images.map((y, ind) => {
                                    // base_image = {
                                    //     path: y.path,
                                    //     url: `/storage/${y.path}`,
                                    //     large_image_url: `/cache/large/${y.path}`,
                                    //     medium_image_url: `/cache/medium/${y.path}`,
                                    //     small_image_url: `/cache/small/${y.path}`,
                                    //     original_image_url: `/cache/original/${y.path}`,
                                    // };
                                    base_image = makeImageClone(y.path)


                                    el.product_images[ind] = base_image
                                })
                            }
                            return el
                        })
                        if (item.product_images.length > 0) {
                            // @ts-ignore
                            item.product_images.map((x, ind) => {
                                // base_image = {
                                //     path: x.path,
                                //     url: `/storage/${x.path}`,
                                //     large_image_url: `/cache/large/${x.path}`,
                                //     medium_image_url: `/cache/medium/${x.path}`,
                                //     small_image_url: `/cache/small/${x.path}`,
                                //     original_image_url: `/cache/original/${x.path}`,
                                // };

                                base_image = makeImageClone(x.path)
                                item.product_images[ind] = base_image
                            })

                        }
                        item.min_price = newMinPrice;
                        return item
                    })
                    resolve(modifiedRes)
                }


            })
            // @ts-ignore
            .catch((err) => reject(err))
    })
}

// @ts-ignore
function Get_Related_Products(options) {
    const {locale, limit, currency, category_id, product_id, selectedRate: rate} = options;
    return new Promise((resolve, reject) => {
        RelatedProducts.find({
            parent_id: {$in: [product_id]},
        })
            // @ts-ignore
            .then((res) => {
                if (res.length > 0) {
                    // @ts-ignore
                    const productIds = res.map((e) => e.child_id);
                    Products.aggregate([{
                        $match: {
                            $and: [{
                                id: {$in: productIds}
                            }, {
                                parent_id: null
                            }]
                        }
                    }, {$limit: limit}, {
                        $lookup: {
                            from: "product_flat",
                            localField: "id",
                            foreignField: "product_id",
                            as: "product_flat",
                            pipeline: [{
                                $match: {
                                    locale: locale
                                }
                            }]
                        }
                    }, {
                        $lookup: {
                            from: "product_inventories",
                            localField: "id",
                            foreignField: "product_id",
                            as: "product_inventories"
                        }
                    }, {
                        $lookup: {
                            from: "product_images", localField: "id", foreignField: "product_id", as: "product_images",
                        }
                    }, {
                        $match: {
                            "product_flat.0": {
                                "$exists": true
                            }
                        }

                    }])
                        // @ts-ignore
                        .then((res) => {

                            res = parseClone(res)
                            // @ts-ignore
                            const modifiedRes = res.map(item => {
                                let base_image;
                                if (item.product_images.length > 0) {
                                    // @ts-ignore
                                    item.product_images.map((x, ind) => {
                                        base_image = makeImageClone(x.path)
                                        item.product_images[ind] = base_image
                                    })

                                }

                                return item
                            })

                            resolve(modifiedRes)

                        })
                } else {
                    ProductsCategories.find({
                        category_id: {$in: category_id},
                        // @ts-ignore
                    }).then((res) => {
                        // @ts-ignore
                        const productIds = res.map((e) => e.product_id);
                        Products.aggregate([{
                            $match: {
                                $and: [{
                                    id: {$in: productIds}
                                }, {
                                    parent_id: null
                                }]
                            }
                        }, {$limit: limit}, {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [{
                                    $match: {
                                        locale: locale
                                    }
                                }]
                            }
                        }, {
                            $lookup: {
                                from: "product_inventories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_inventories"
                            }
                        }, {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        }, {
                            $match: {
                                "product_flat.0": {
                                    "$exists": true
                                }
                            }
                        }])
                            // @ts-ignore
                            .then((res) => {
                                res = parseClone(res)
                                // @ts-ignore
                                const modifiedRes = res.map(item => {
                                    let base_image;
                                    if (item.product_images.length > 0) {
                                        // @ts-ignore
                                        item.product_images.map((x, ind) => {
                                            base_image = makeImageClone(x.path)
                                            item.product_images[ind] = base_image
                                        })

                                    }

                                    return item
                                })

                                resolve(modifiedRes)

                            })
                    });
                }
            })
            // @ts-ignore
            .catch((err) => reject(err));
    });
}

//@ts-ignore
function Get_Up_Sell_Products(options) {
    const {locale, limit, rate: currency, product_id} = options;
    return new Promise((resolve, reject) => {
        UpSellProducts.find({
            parent_id: {$in: product_id.split(",")},
        })
            //@ts-ignore
            .then((res) => {
                if (res.length > 0) {
                    //@ts-ignore
                    const productIds = res.map((e) => e.child_id);
                    Products.aggregate([
                        {
                            $match: {
                                $and: [
                                    {
                                        id: {$in: productIds}
                                    },
                                    {
                                        parent_id: null
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_super_attributes",
                                localField: "id",
                                foreignField: "product_id",
                                as: "variants",

                            }
                        },
                        {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [
                                    {
                                        $match: {
                                            locale: locale
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_inventories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_inventories"
                            }
                        },
                        {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        },
                        {
                            $lookup: {
                                from: "products",
                                localField: "id",
                                foreignField: "parent_id",
                                as: "products",
                                let: {id: "$id"},
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [
                                                {
                                                    $match: {
                                                        locale: locale
                                                    }
                                                }
                                            ]
                                        },
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    }
                                ]
                            }
                        }
                    ])
                        .limit(Number(limit))
                        //@ts-ignore
                        .then((res) => {
                            res = parseClone(res)
                            //@ts-ignore
                            const modifiedRes = res.map(item => {
                                let base_image;
                                if (item.product_images.length > 0) {
                                    //@ts-ignore
                                    item.product_images.map((x, ind) => {
                                        base_image = makeImageClone(x.path)
                                        item.product_images[ind] = base_image
                                    })

                                }
                                return item
                            })
                            resolve(modifiedRes)

                        })
                } else {
                    resolve(res);
                }
            })
            //@ts-ignore
            .catch(err => reject(err));
    });
}

//@ts-ignore
function Get_Cross_Sell_Products(options) {
    const {locale, limit, rate: currency, product_id} = options;
    return new Promise((resolve, reject) => {
        CrosselProducts.find({
            parent_id: {$in: product_id.split(",")},
        })
            // @ts-ignore
            .then((res) => {
                if (res.length > 0) {
                    // @ts-ignore
                    const productIds = res.map((e) => e.child_id);
                    Products.aggregate([
                        {
                            $match: {
                                $and: [
                                    {
                                        id: {$in: productIds}
                                    },
                                    {
                                        parent_id: null
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_super_attributes",
                                localField: "id",
                                foreignField: "product_id",
                                as: "variants",

                            }
                        },
                        {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [
                                    {
                                        $match: {
                                            locale: locale
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_inventories",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_inventories"
                            }
                        },
                        {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        },
                        {
                            $lookup: {
                                from: "products",
                                localField: "id",
                                foreignField: "parent_id",
                                as: "products",
                                let: {id: "$id"},
                                pipeline: [
                                    {
                                        $lookup: {
                                            from: "product_flat",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_flat",
                                            pipeline: [
                                                {
                                                    $match: {
                                                        locale: locale
                                                    }
                                                }
                                            ]
                                        },
                                    },
                                    {
                                        $lookup: {
                                            from: "product_images",
                                            localField: "id",
                                            foreignField: "product_id",
                                            as: "product_images",
                                        }
                                    }
                                ]
                            }
                        }
                    ])
                        .limit(Number(limit))
                        // @ts-ignore
                        .then((res) => {
                            res = parseClone(res)
                            // @ts-ignore
                            const modifiedRes = res.map(item => {
                                let base_image;
                                if (item.product_images.length > 0) {
                                    // @ts-ignore
                                    item.product_images.map((x, ind) => {
                                        base_image = makeImageClone(x.path)
                                        item.product_images[ind] = base_image
                                    })

                                }

                                return item
                            })

                            const randomProducts = [];
                            const newProducts = [];
                            while (randomProducts.length < modifiedRes.length) {
                                let r = Math.floor(Math.random() * modifiedRes.length);
                                if (randomProducts.indexOf(r) === -1) randomProducts.push(r);
                            }
                            for (let i = 0; i < modifiedRes.length; i++) {
                                newProducts.push(modifiedRes[randomProducts[i]])
                            }


                            resolve(newProducts)

                        })
                } else {
                    resolve(res);
                }
            })
            // @ts-ignore
            .catch(err => reject(err));
    });
}

// function Get_Configurable_Config(configurableId) {
//     return new Promise( (resolve, reject) => {
//         Products
//             .findOne( {id: configurableId} )
//             .then( (items) => {
//                 const product = parseClone( items );
//
//                 ProductSuperAttributes
//                     .find( {product_id: product.id} )
//                     .then( (productSuperAttribute) => {
//                         const superAttributes = parseClone( productSuperAttribute );
//                         const attrinutesKeysList = superAttributes.map( (attrObj) => {
//                             return Object.keys( attrObj ).find( (key) => {
//                                 if (key !== "_id" && key !== "product_id") {
//                                     return key;
//                                 }
//                             } );
//                         } );
//
//                         return new Promise( (resolve, rejectAttr) => {
//                             Attributes
//                                 .find( {code: {$in: attrinutesKeysList}} )
//                                 .then( (items) => {
//                                     const attributes = parseClone( items );
//                                     const attributesId = attributes.map( (e) => e.id );
//
//                                     return new Promise( (resolve, rejectOpt) => {
//                                         AttributeOptions
//                                             .find( {
//                                                 attribute_id: {$in: attributesId},
//                                             } )
//                                             .then( (items) => {
//                                                 resolve( parseClone( items ) );
//                                             } )
//                                             .catch( err => rejectOpt( err ) );
//                                     } )
//                                         .then( (options) => {
//                                             resolve( {
//                                                 attributesId, attributes, options,
//                                             } );
//                                         } );
//                                 } )
//                                 .catch( err => rejectAttr( err ) );
//                         } )
//                             .then( ({attributesId, attributes: responseAttributes, options}) => {
//
//                                 ProductAttributeValues
//                                     .find( {
//                                         attribute_id: {$in: attributesId},
//                                     } )
//                                     .then( (res) => {
//                                         const productsAttributesValues = parseClone( res );
//                                         Products
//                                             .find( {parent_id: product.id} )
//                                             .then( (items) => {
//                                                 const simplesProducts = parseClone( items );
//                                                 const simplesProductsIds = simplesProducts.map( (e) => e.id );
//
//                                                 let index = {};
//                                                 let attributes = responseAttributes.map( (attr) => {
//                                                     const {code} = attr;
//
//                                                     const customOptionsCollection = [];
//                                                     const attributeValuesFiltered = productsAttributesValues
//                                                         .filter( (values) => values.attribute_id == attr.id )
//                                                         .filter( (attribute) => {
//                                                             if (simplesProductsIds.includes( attribute.product_id )) {
//                                                                 return attribute;
//                                                             }
//                                                         } );
//
//                                                     for (let i = 0; i < attributeValuesFiltered.length; i++) {
//                                                         const attributeValue = attributeValuesFiltered[i];
//                                                         let optionId = null;
//                                                         const keys = Object.keys( attributeValue );
//                                                         for (let i = 0; i < keys.length; i++) {
//                                                             const key = keys[i];
//                                                             if (key != "_id" && key != "product_id" && key != "attribute_id" && key != "id" && attributeValue[key]) {
//                                                                 optionId = attributeValue[key];
//                                                                 break;
//                                                             }
//                                                         }
//                                                         if (index[attributeValue.product_id]) {
//                                                             index[attributeValue.product_id][attributeValue.attribute_id] = optionId;
//                                                         } else {
//                                                             index[attributeValue.product_id] = {
//                                                                 [attributeValue.attribute_id]: optionId,
//                                                             };
//                                                         }
//                                                     }
//
//                                                     const attributeValues = attributeValuesFiltered.map( (attribute) => {
//                                                         let optionId = null;
//
//                                                         const keys = Object.keys( attribute );
//                                                         for (let i = 0; i < keys.length; i++) {
//                                                             const key = keys[i];
//                                                             if (key != "_id" && key != "product_id" && key != "attribute_id" && key != "id" && attribute[key]) {
//                                                                 optionId = attribute[key];
//                                                                 break;
//                                                             }
//                                                         }
//
//                                                         const found = options.find( (option) => option.id == optionId );
//                                                         if (found) {
//                                                             return found;
//                                                         }
//                                                     } );
//
//                                                     const results = attributeValues.filter( (element) => {
//                                                         return element !== undefined;
//                                                     } );
//                                                     results.forEach( (option) => {
//                                                         if (!customOptionsCollection.find( (opt) => opt.attribute_id == option.attribute_id && opt.id == option.id )) {
//                                                             const products = [];
//                                                             for (let key in index) {
//                                                                 if (key && index[key] && index[key][option.attribute_id] && index[key][option.attribute_id] == option.id) {
//                                                                     products.push( parseInt( key ) );
//                                                                 }
//                                                             }
//                                                             customOptionsCollection.push( {
//                                                                 ...option, products: products,
//                                                             } );
//                                                         }
//                                                     } );
//
//                                                     const {product_id} = superAttributes.find( (superAttr) => {
//                                                         const keys = Object.keys( superAttr );
//                                                         if (keys.includes( code )) {
//                                                             return superAttr;
//                                                         }
//                                                     } );
//
//                                                     return {
//                                                         id: attr.id,
//                                                         code: attr.code,
//                                                         label: attr.admin_name,
//                                                         options: customOptionsCollection,
//                                                         swatch_type: null,
//                                                         product_id,
//                                                     };
//                                                 } );
//
//                                                 const imageVariants = new Promise( (resolve, reject) => {
//                                                     ProductImages.find( {
//                                                         product_id: {$in: items.map( (e) => e.id )},
//                                                     } ).then( (images) => {
//                                                         const imagesCollection = images.reduce( (acc, next) => {
//                                                             if (acc[next.product_id]?.length > 0) {
//                                                                 const newArray = acc[next.product_id];
//                                                                 newArray.unshift( makeImageClone( next.path ) );
//                                                                 return {...acc, [next.product_id]: [newArray]};
//                                                             }
//                                                             return {
//                                                                 ...acc,
//                                                                 [next.product_id]: [makeImageClone( next.path )],
//                                                             };
//                                                         }, {} );
//                                                         resolve( {images: imagesCollection} );
//                                                     } )
//                                                         .catch( err => reject( err ) );
//                                                 } );
//
//                                                 const imagePriceVariantes = new Promise( (resolve, reject) => {
//                                                     ProductFlat.find( {
//                                                         product_id: {$in: items.map( (e) => e.id )},
//                                                     } )
//                                                         .then( (flats) => {
//                                                             const titleDescVariantes = flats.reduce( (acc, next) => {
//
//                                                                 return {
//                                                                     ...acc, [next.product_id]: {
//                                                                         title: next?.name || null,
//                                                                         description: next?.description || null,
//                                                                     },
//                                                                 };
//                                                             }, {} );
//
//                                                             const priceVariantes = flats.reduce( (acc, next) => {
//                                                                 return {
//                                                                     ...acc, [next.product_id]: {
//                                                                         final_price: {
//                                                                             formated_price: "static string by david check that with Ruben",
//                                                                             price: "static string by david check that with Ruben",
//                                                                         }, regular_price: {
//                                                                             formated_price: "static string by david check that with Ruben",
//                                                                             price: "static string by david check that with Ruben",
//                                                                         },
//                                                                     },
//                                                                 };
//                                                             }, {} );
//
//                                                             resolve( {
//                                                                 variant_prices: priceVariantes,
//                                                                 variant_title_desc: titleDescVariantes,
//                                                             } );
//                                                         } )
//                                                         .catch( (err) => reject( err ) );
//                                                 } );
//
//                                                 return Promise.all( [imageVariants, imagePriceVariantes] ).then( (res) => {
//                                                     const response = arrayConvertToObject( res );
//                                                     const mainResponse = {
//                                                         attributes: attributes,
//                                                         chooseText: "Choose an option",
//                                                         index: index,
//                                                         variant_images: response.images,
//                                                         variant_prices: response.variant_prices,
//                                                         variant_title_desc: response.variant_title_desc,
//                                                         variant_videos: [],
//                                                         regular_price: {
//                                                             formated_price: "$1.00", price: "1.0000",
//                                                         },
//                                                     };
//
//                                                     resolve( {data: mainResponse} );
//                                                 } );
//                                             } );
//                                     } );
//                             } )
//                     } );
//             } )
//             .catch( err => reject( err ) );
//     } );
// }
//
// async function getBundleOptionsProducts(productId, locale) {
//
//     return new Promise( (resolve, reject) => {
//
//         const p1 = new Promise( (resolve, reject1) => {
//             ProductImages
//                 .find( {product_id: productId} )
//                 .then( (res) => resolve( {ProductImages: res} ) )
//                 .catch( (err) => reject1( err ) );
//         } );
//         const p2 = new Promise( (resolve, reject2) => {
//             ProductFlat
//                 .findOne( {product_id: productId} )
//                 .then( (res) => resolve( {productFlat: res} ) )
//                 .catch( (err) => reject2( err ) );
//         } );
//         const p3 = new Promise( (resolve, reject3) => {
//             ProductInventories
//                 .findOne( {product_id: productId} )
//                 .then( (res) => resolve( {ProductInventories: res} ) )
//                 .catch( (err) => reject3( err ) );
//         } );
//         Promise.all( [p1, p2, p3] )
//             .then( (response) => {
//                 const collection = arrayConvertToObject( response );
//                 const imagesData = parseClone( collection.ProductImages );
//                 const flatData = parseClone( collection.productFlat );
//                 const inventoriesData = parseClone( collection.ProductInventories );
//
//                 if (imagesData[0] && imagesData[0].path) {
//                     const {path} = imagesData[0];
//                     const base_image = makeImageClone( path );
//
//                     const obj = {
//                         ...flatData, ...inventoriesData, base_image,
//                     };
//                     resolve( obj );
//                 }
//             } )
//     } );
//
// }
//
// async function Get_Bundle_Products(id, product, item, locale, count) {
//     return new Promise( (resolve, reject) => {
//         let objArr = [];
//         let mapPromise
//         BundleProducts
//             .find( {product_bundle_option_id: id} )
//             .then( (pr) => {
//                 product.options[count] = parseClone( product.options[count] );
//                 product.options[count].bundleProducts = pr
//                 pr = parseClone( pr )
//
//                 pr.map( (element, index) => {
//
//                     mapPromise = new Promise( (resolve, reject) => {
//                         getBundleOptionsProducts( element.product_id, locale )
//                             .then( (response) => {
//
//                                 product.options[count].bundleProducts = parseClone( product.options[count].bundleProducts )
//                                 product.options[count].bundleProducts[index] = response
//                                 product.options[count].bundleProducts[index].bundle_configs = element
//                                 resolve( product )
//                             } )
//                             .catch( err => reject( err ) )
//                     } )
//
//                     objArr.push( mapPromise )
//
//                 } )
//
//             } )
//             .then( () => {
//                 Promise.all( objArr ).then( values => {
//                     resolve( values )
//                 } );
//             } )
//     } )
//
// }
//
// async function Get_Bundle_Option(product, newData, locale) {
//     return new Promise( (resolve, reject) => {
//         var array = [];
//         BundleOptions
//             .find( {product_id: product.id} )
//             .then( (item) => {
//                 product.options = item;
//                 item.map( (elem, index) => {
//                     var two = new Promise( function (resolve, reject) {
//                         // Do Stuff
//                         resolve( Get_Bundle_Products( elem.id, product, item, locale, index ) );
//                     } );
//                     array.push( two );
//                 } )
//                 Promise.all( array ).then( values => {
//                     resolve( values )
//                 } );
//             } )
//     } )
//
// }
//
// function Get_Bundle_Prods(options){
//     const bundleId = options.id;
//     const locale = options.locale;
//     var newData = [];
//     var product
//     return new Promise( (resolve, reject) => {
//         Products
//             .findOne( {id: bundleId} )
//             .then( async (items) => {
//                 product = parseClone( items );
//                 await Get_Bundle_Option( product, newData, locale ).then( res => {
//                     let parsedData = parseClone( res )
//                     resolve( parsedData );
//                 } )
//             } )
//             .catch( err => reject( err ) )
//     } );
// }
//
// exports.Get_Bundle_Prods = Get_Bundle_Prods
//
// const GetFiltreConfig = (id) => {
//     return new Promise( (resolve, reject) => {
//         ProductFlat
//             .find( {parent_id: id} )
//             .then( (res) => resolve( res ) )
//             .catch( (err) => reject( err ) );
//     } );
// }
//
// exports.default = GetFiltreConfig

// @ts-ignore
const GetCategoryProducts = (slug, locale, limit) => {
    return new Promise((resolve, reject) => {
        // @ts-ignore
        ProductsCategories.find({slug}).then((res) => {
            // @ts-ignore
            const productIdsByCategory = res.map((e) => e.product_id);
            Products.aggregate([
                {
                    $match: {
                        $and: [
                            {
                                id: {$in: productIdsByCategory}
                            },
                            {
                                parent_id: null
                            }
                        ]
                    }
                },
                {
                    $lookup: {
                        from: "product_super_attributes",
                        localField: "id",
                        foreignField: "product_id",
                        as: "variants",
                    }
                },
                {
                    $lookup: {
                        from: "product_flat",
                        localField: "id",
                        foreignField: "product_id",
                        as: "product_flat",
                        pipeline: [
                            {
                                $match: {
                                    locale: locale
                                }
                            }
                        ]
                    }
                },
                {
                    $lookup: {
                        from: "product_inventories",
                        localField: "id",
                        foreignField: "product_id",
                        as: "product_inventories"
                    }
                },
                {
                    $lookup: {
                        from: "product_images",
                        localField: "id",
                        foreignField: "product_id",
                        as: "product_images",
                    }
                },
                {
                    $lookup: {
                        from: "products",
                        localField: "id",
                        foreignField: "parent_id",
                        as: "products",
                        let: {id: "$id"},
                        pipeline: [
                            {
                                $lookup: {
                                    from: "product_flat",
                                    localField: "id",
                                    foreignField: "product_id",
                                    as: "product_flat",
                                    pipeline: [
                                        {
                                            $match: {
                                                locale: locale
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                $lookup: {
                                    from: "product_images",
                                    localField: "id",
                                    foreignField: "product_id",
                                    as: "product_images",
                                }
                            }
                        ]
                    }

                }
            ])
                // @ts-ignore
                .then((res) => {
                    let setMinPrice;
                    let setMaxPrice;
                    if (res.length === 1) {
                        // @ts-ignore
                        setMinPrice = setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                    } else {
                        // @ts-ignore
                        setMinPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                        // @ts-ignore
                        setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], 1)).sort((a, b) => b - a)[0]
                    }
                    // @ts-ignore
                    let confMinPrice
                    let confMaxPrice;
                    // @ts-ignore
                    res.map((item) => {
                        if (item.products.length > 0) { // @ts-ignore
                            confMinPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], -1)).sort((a, b) => a - b)[0]
                            // @ts-ignore
                            confMaxPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], 1)).sort((a, b) => b - a)[0]
                        }
                        // @ts-ignore
                        item.min_price = confMinPrice;
                    })

                    let min = 0
                    let max = 0
                    if ((Number(confMinPrice) > Number(setMinPrice)) || !isNaN(Number(setMinPrice))) {
                        min = setMinPrice
                    } else {
                        // @ts-ignore
                        min = confMinPrice
                    }

                    if ((Number(confMaxPrice) > Number(setMaxPrice)) || isNaN(Number(setMaxPrice))) {
                        // @ts-ignore
                        max = confMaxPrice
                    } else {
                        max = setMaxPrice
                    }
                    const everyThing = {
                        data: res,
                        filters: [],
                        links: {},
                        max_price: setMaxPrice || 0,
                        meta: {},
                        total: 100,
                        dispatches: {
                            setInitialMinPrice: min || 0, setInitialMaxPrice: max || 0,
                        },
                    };

                    resolve(everyThing)
                })

        })

    });
}

// const Get_Indicator_Cart_Products = (product) => {
//     return new Promise( (resolve, reject) => {
//         const productIDS = product.map( item => item.product.id )
//         const locale = 'en'
//         Products.aggregate( [{
//             $match: {
//                 $and: [{
//                     id: {$in: productIDS}
//                 }]
//             }
//         }, {
//             $lookup: {
//                 from: "product_flat", localField: "id", foreignField: "product_id", as: "product_flat", pipeline: [{
//                     $match: {
//                         locale: locale
//                     }
//                 },]
//             }
//         }, {
//             $lookup: {
//                 from: "product_inventories", localField: "id", foreignField: "product_id", as: "product_inventories"
//             }
//         }, {
//             $lookup: {
//                 from: "product_images", localField: "id", foreignField: "product_id", as: "product_images",
//             }
//         }] )
//             .then( (res) => {
//                 res = parseClone( res )
//                 const modifiedRes = res.map( item => {
//                     let base_image;
//                     if (item.product_images.length > 0) {
//                         item.product_images.map( (x, ind) => {
//                             base_image = makeImageClone( x.path )
//                             item.product_images[ind] = base_image
//                         } )
//
//                     }
//
//                     return item
//                 } )
//
//                 resolve( modifiedRes )
//
//             } )
//             .catch( (err) => reject( err ) );
//
//     } )
// }
//
// exports.default = Get_Indicator_Cart_Products
//
//
// ///exports.GetFiltreConfig = GetFiltreConfig;
// ///exports.Get_Configurable_Config = Get_Configurable_Config;
// exports.Get_Bundle_Prods = Get_Bundle_Prods;
// ///exports.Get_Related_Products = Get_Related_Products;
// ///exports.Get_Up_Sell_Products = Get_Up_Sell_Products;
// ////exports.Get_Cross_Sell_Products = Get_Cross_Sell_Products;
// ///exports.Get_config_variants = Get_config_variants;
// ///exports.Get_Indicator_Cart_Products = Get_Indicator_Cart_Products;
//

//@ts-ignore
const GetAllProducts = ( locale) => {
    return new Promise((resolve, reject) => {
        // @ts-ignore
        Products.aggregate([
            {
                $lookup: {
                    from: "product_super_attributes",
                    localField: "id",
                    foreignField: "product_id",
                    as: "variants",
                }
            },
            {
                $lookup: {
                    from: "product_flat",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_flat",
                    pipeline: [
                        {
                            $match: {
                                locale: locale
                            }
                        }
                    ]
                }
            },
            {
                $lookup: {
                    from: "product_inventories",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_inventories"
                }
            },
            {
                $lookup: {
                    from: "product_images",
                    localField: "id",
                    foreignField: "product_id",
                    as: "product_images",
                }
            },
            {
                $lookup: {
                    from: "products",
                    localField: "id",
                    foreignField: "parent_id",
                    as: "products",
                    let: {id: "$id"},
                    pipeline: [
                        {
                            $lookup: {
                                from: "product_flat",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_flat",
                                pipeline: [
                                    {
                                        $match: {
                                            locale: locale
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            $lookup: {
                                from: "product_images",
                                localField: "id",
                                foreignField: "product_id",
                                as: "product_images",
                            }
                        }
                    ]
                }

            }
        ])
        // @ts-ignore
        .then((res) => {
            let setMinPrice;
            let setMaxPrice;
            if (res.length === 1) {
                // @ts-ignore
                setMinPrice = setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
            } else {
                // @ts-ignore
                setMinPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], -1)).sort((a, b) => a - b)[0]
                // @ts-ignore
                setMaxPrice = res.map((item) => findMinimalOrMaximalPrice(item.product_flat[0], 1)).sort((a, b) => b - a)[0]
            }
            // @ts-ignore
            let confMinPrice
            let confMaxPrice;
            // @ts-ignore
            res.map((item) => {
                if (item.products.length > 0) { // @ts-ignore
                    confMinPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], -1)).sort((a, b) => a - b)[0]
                    // @ts-ignore
                    confMaxPrice = item.products.map((el) => findMinimalOrMaximalPrice(el.product_flat[0], 1)).sort((a, b) => b - a)[0]
                }
                // @ts-ignore
                item.min_price = confMinPrice;
            })

            let min = 0
            let max = 0
            if ((Number(confMinPrice) > Number(setMinPrice)) || !isNaN(Number(setMinPrice))) {
                min = setMinPrice
            } else {
                // @ts-ignore
                min = confMinPrice
            }

            if ((Number(confMaxPrice) > Number(setMaxPrice)) || isNaN(Number(setMaxPrice))) {
                // @ts-ignore
                max = confMaxPrice
            } else {
                max = setMaxPrice
            }
            const everyThing = {
                data: res,
                filters: [],
                links: {},
                max_price: setMaxPrice || 0,
                meta: {},
                total: 100,
                dispatches: {
                    setInitialMinPrice: min || 0, setInitialMaxPrice: max || 0,
                },
            };

            resolve(everyThing)
        })
    })
}

module.exports = {
    Get_New_And_Featured_Products,
    Get_Product_For_Product_Inner_Page,
    GetCategoryProducts,
    Get_Product_list,
    Get_Related_Products,
    Get_Cross_Sell_Products,
    Get_Up_Sell_Products,
    GetAllProducts,
}
