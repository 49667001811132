const Attributes = require("../models/Attributes.js");
const AttributeOptions = require("../models/AttributeOptions.js");
const CategoryFilterableAttributes = require("../models/CategoryFilterableAttributes.js");

function Get_Filter_Old(props) {
    const {lang, category_id} = props;

    return new Promise((resolve, reject) => {
        CategoryFilterableAttributes.find({category_id: category_id})
            .then(response => {
                    const attributesIds = response.map((e) => parseInt(e.attribute_id));
                    Attributes
                        .find({id: {$in: attributesIds}})
                        .then(attributes => {
                            AttributeOptions
                                .find({attribute_id: {$in: attributesIds}})
                                .then(items => {
                                        const options = JSON.parse(JSON.stringify(items));
                                        const formattedAttributes = attributes.map((item) => {
                                            return new Promise((resolve1, reject) => {
                                                const attribute = JSON.parse(JSON.stringify(item));
                                                const {translations} = attribute;
                                                const name = translations.find(item => item.locale == lang);
                                                if (!name) {
                                                    resolve1([]);
                                                }

                                                attribute.label = name.label ? name.label : name?.name;
                                                attribute.options = options.filter(option => {

                                                    if (option.attribute_id == attribute.id) {
                                                        const find = option?.translations.find(option => option.locale == lang);
                                                        option.label = find.label;
                                                        delete option["translations"];
                                                        return option;
                                                    }
                                                });

                                                delete attribute["translations"];
                                                delete attribute["_id"];

                                                resolve1(attribute);
                                            });
                                        });
                                        return Promise
                                            .all(formattedAttributes)
                                            .then(response => resolve(response));
                                    }
                                );
                        });
                }
            );
    });
}

function Get_Filters(props) {
    const {lang, category_id} = props;

    return new Promise((resolve, reject) => {

        CategoryFilterableAttributes.aggregate([
            {
                $match: {category_id: category_id}
            },
            {
                $lookup: {
                    from: "attributes",
                    localField: "attribute_id",
                    foreignField: "id",
                    as: "attributes",
                    pipeline: [{
                        $match: {
                            "translations": {
                                "$all": [
                                    {"$elemMatch": {"locale": lang}}

                                ]
                            }
                        }
                    }]
                }
            },
            {
                $lookup: {
                    from: "attribute_options",
                    localField: "attribute_id",
                    foreignField: "attribute_id",
                    as: "attribute_options",
                    pipeline: [{
                        $match: {
                            "translations": {
                                "$all": [
                                    {"$elemMatch": {"locale": lang}}

                                ]
                            }
                        }
                    }]
                }
            },
        ])
            .then(response => {
                    resolve(response)
                }
            );
    });
}

function Get_All_Filters(props) {
    const {lang} = props;

    return new Promise((resolve, reject) => {

        CategoryFilterableAttributes.aggregate([
            {
                $lookup: {
                    from: "attributes",
                    localField: "attribute_id",
                    foreignField: "id",
                    as: "attributes",
                    pipeline: [{
                        $match: {
                            "translations": {
                                "$all": [
                                    {"$elemMatch": {"locale": lang}}

                                ]
                            }
                        }
                    }]
                }
            },
            {
                $lookup: {
                    from: "attribute_options",
                    localField: "attribute_id",
                    foreignField: "attribute_id",
                    as: "attribute_options",
                    pipeline: [{
                        $match: {
                            "translations": {
                                "$all": [
                                    {"$elemMatch": {"locale": lang}}

                                ]
                            }
                        }
                    }]
                }
            },
        ])
            .then(response => {
                const uniqueResponse = response.reduce((unique, item) => {
                    if (!unique.some(i => i.attributes[0].code === item.attributes[0].code)) {
                        unique.push(item);
                    }
                    return unique;
                }, []);

                    resolve(uniqueResponse)
                }
            );
    });
}

exports.Get_Filters = Get_Filters;
exports.Get_All_Filters = Get_All_Filters;
