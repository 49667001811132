const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({
    data: {
        type: Object,
        required: true,
    }
});

module.exports = mongoose.models && mongoose.models.Categories_hy
    ? mongoose.models.Categories_hy
    : mongoose.model("Categories_hy", postSchema, 'categories_hy', false);
