const mongoose = require("mongoose");

const connectMongoose = async (fullName) => {
    //// REMEBER GETTING DB NAME URL FULL DOMAIN
    const dbName = fullName;
    var databaseName;
    if (dbName.includes(process.env.APP_SUFFIX)) {
        var dataName = dbName.split(process.env.APP_SUFFIX);
        databaseName = dataName[0];
        process.env.domainName = dbName;

        process.env.databaseName = databaseName;
    } else {
        process.env.domainName = dbName;
        databaseName = dbName.split(".")[0];
        if (databaseName == "www") {
            databaseName = dbName.split(".")[1];
        }
        process.env.databaseName = databaseName;
    }
    let urlObject = new URL(process.env.MONGO_URL)
    urlObject.pathname = databaseName;

    const url = urlObject.toString()
    let response = "";
    try {
        await mongoose
            .connect(url, {
                    useNewUrlParser: true,
                    useUnifiedTopology: true,

                    autoIndex: false,

                    connectTimeoutMS: 0,
                    socketTimeoutMS: 0,
                    family: 4,
                }
            )
            .then(() => {
                response = "Builder DB Conencted Successfully";
            })
            .catch((err) => {
                response = "Builder DB Conenction Failed";
                console.error("Something went wrong", err);
            });
    } catch (err) {
        console.error(err);
        process.exit(1);
    }
};

const checkMongoConnected = async (req, res) => {
    mongoose.connect(`mongodb://localhost:27017/${process.env.databaseName}`, function (error) {
        if (error) {
            return res.send("Database error or database connection error " + error);
        }

        let status = "";
        if (mongoose.connection.readyState == 0) {
            status = "Disconnected";
        } else if (mongoose.connection.readyState == 1) {
            status = "Connected";
        } else if (mongoose.connection.readyState == 2) {
            status = "Connecting";
        } else if (mongoose.connection.readyState == 3) {
            status = "Disconnecting";
        }
    });
};

module.exports = {connectMongoose, checkMongoConnected};

