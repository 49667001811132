// import Builder from "../models/Builder.js";
const Builder = require("../models/Builder");
//
// function parseClone(obj) {
//     return JSON.parse(JSON.stringify(obj));
// }
// @ts-ignore
function Save_Builder_data(body) {
    console.log(body, "boooofy")
    return new Promise(async (resolve, reject) => {
        Builder.update({page_id: 2}, {
            page_id: body.page_id,
            tree: body.tree,
            components: body.children,
            initialLayout: body.initialLayout,
            componentsBuilder: body.componentsBuilder
        }, {
            upsert: true
        }).then(function () {
            resolve("the item is saved successfully")
        })
    })

}

// @ts-ignore
// function DeleteBuilderData(body) {
//
//     console.log(body,'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
//     return new Promise(async (resolve, reject) => {
//         Builder.update({page_id: 2}, {
//             page_id: body.page_id,
//             componentId: body.componentId
//         }).then(function () {
//
//             resolve("deleted successfully")
//         })
//     })
//
// }

// exports.Save_Builder_data = Save_Builder_data
// ////exports.Save_Builder_data = Save_Builder_data;
// @ts-ignore
function Get_Data(pageId) {

    return new Promise(async (resolve, reject) => {
        Builder.aggregate([{
            $match: {
                page_id: pageId
            },
        },
            // {$limit: 1}
        ])
            // @ts-ignore
            .then((res) => {
                resolve(JSON.parse(JSON.stringify(res)))
            })
            // @ts-ignore
            .catch((err) => reject(err))
    })
}

const Get_sliders_image = () => {

}
module.exports = {
    Get_sliders_image,
    Get_Data,
    Save_Builder_data,
    // DeleteBuilderData
}

