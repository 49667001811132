const mongoose = require("mongoose");
const Schema = mongoose.Schema;
const postSchema = new Schema({

    page_id: {
        type: Number,
        required: true,
    },
    tree: {
        type: Object,
        required: true,
    },
    components: {
        type: Object,
        required: true,
    },
    componentsBuilder: {
        type: Object,
        required: true,
    },
    initialLayout: {
        type: Array,
        required: true,
    },
}, {strict: false});

module.exports = mongoose.models && mongoose.models.Page_builder
    ? mongoose.models.Page_builder
    : mongoose.model("Page_builder", postSchema, 'page_builder', false);